.rowEven {
  background-color: #f8f9fa;
}

.rowOdd {
  background-color: #fefeff;
}

.rowEven:hover,
.rowOdd:hover {
  background-color: #ced4da;
}

.addHover:hover {
  background-color: #ced4da;
}
