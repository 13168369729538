body {
  background-color: rgba(254, 250, 224, 0.5);
}

.dashboard {
  font-family: Arial, sans-serif;
  max-width: 100%;
  margin: 0 auto;
  padding: 15px 0;
}

.dashboard-title {
  text-align: center;
  color: rgba(57, 80, 50, 0.8);
  background-color: rgba(209, 243, 197, 0.6);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.date-picker-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.loading, .error {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: rgba(57, 80, 50, 0.8);
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(241, 243, 245, 0.6);
}

::-webkit-scrollbar-thumb {
  background: rgba(189, 195, 199, 0.6);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(149, 165, 166, 0.6);
}

